<template>
  <div class="nearby-places--field mb-2">
    <span class="mr-2">{{ index + 1 }}. </span>
    <v-text-field
      id="nameField"
      class="mr-2"
      :value="nearbyPlace.name"
      @input="inputName"
      type="text"
      name="nearbyPlaces[]"
      outlined
      hide-details
    />
    <v-text-field
      id="nameField"
      class="distance--field mr-2"
      :value="nearbyPlace.distance_in_km"
      @input="inputDistance"
      type="text"
      name="nearbyPlaces[]"
      :placeholder="$t('project.distancePlc')"
      outlined
      hide-details
      suffix="KM"
    />
    <v-btn color="error" @click="removeNearbyPlace">
      <v-icon v-text="'mdi-trash-can-outline'" />
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'nearby-place-field',
  props: ['nearbyPlace', 'index', 'nearbyPlaceLength'],
  methods: {
    inputName(input) {
      this.$store.commit('project/form/INPUT_NEARBY_PLACE_NAME', {
        name: input,
        index: this.index,
      });
    },
    inputDistance(input) {
      this.$store.commit('project/form/INPUT_NEARBY_PLACE_DISTANCE', {
        name: input,
        index: this.index,
      });
    },
    removeNearbyPlace() {
      this.$store.commit('project/form/REMOVE_NEARBY_PLACE', this.index);
    },
  },
};
</script>

<style scoped></style>
